@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');



body {
  font-family: 'Nunito Sans', sans-serif;
}

/* ::-webkit-scrollbar {
  display: none;
} */
a {
  text-decoration: none;
}
